<template>
  <b-card>

    <authorization-guard
      permission="cadre.safety_training.index"
      silent
    >
      <cadre-employees-safety-trainings-filtration />
    </authorization-guard>

    <div class="buttons">
      <div class="group">
        <vs-button
          v-b-modal.modal-cadre-employee-add
          vs-variant="primary"
          v-can:store="'cadre.safety_training'"
          @click="openTrainingAddModal"
        >
          + {{ $t('cadre.safetyTrainings.list.button.add') }}
        </vs-button>
      </div>
    </div>
    <br>

    <authorization-guard
      permission="cadre.safety_training.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.cadre.trainings.trainings"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="getColumnSortType"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        :showSearch="false"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        @on-sort-change="onSortChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'name'">
            {{ row.name }}
          </span>
          <span v-else-if="column.field === 'employee'">
            <cadre-employee-label
              isEmployee
              withAvatar
              nameOnly
              :data="row.employee"
            />
          </span>
          <span v-else-if="column.field === 'training_date'">
            {{ formatDate.training(row.training_date) }}
          </span>
          <span v-else-if="column.field === 'expiration_date'">
            <examination-badge
              :expired="!row || daysFromToday(row.expiration_date) > 0"
              :about-to-expire="row && row.about_to_expire"
              :date="row.expiration_date"
              v-if="row.expiration_date"
            />
            <span v-else>-</span>
          </span>
          <span v-else-if="column.field === 'attachment'">
            <vs-button
              vs-variant="light"
              @click="() => openAttachment(row.file)"
              v-if="row.file"
              >
              Pobierz załącznik
            </vs-button>
          </span>
        </template>
      </vs-table>
    </authorization-guard>

    <cadre-training-add
      @success="fetchData"
    />
    <cadre-training-edit
      @success="fetchData"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BCard,
} from 'bootstrap-vue';

import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import CadreEmployeeLabel from '@/components/views/cadre/ui/CadreEmployeeLabel.vue';

import cadreSafetyTrainings from '@/hooks/tables/cadre/trainings';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import CadreTrainingAdd from '@/components/views/cadre/ui/safety-trainings/modals/CadreTrainingAdd.vue';
import CadreTrainingEdit from '@/components/views/cadre/ui/safety-trainings/modals/CadreTrainingEdit.vue';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import CadreEmployeesSafetyTrainingsFiltration from '@/components/views/cadre/CadreEmployeesSafetyTrainingsFiltration.vue';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import { DeleteTrainingError, DeleteTrainingSuccess } from '@/libs/toasts/feedback/module/cadre';
import showToast from '@/libs/toasts';

export default {
  components: {
    BCard,

    VsButton,
    VsTable,

    AuthorizationGuard,
    CadreEmployeeLabel,
    CadreTrainingAdd,
    CadreTrainingEdit,
    CadreEmployeesSafetyTrainingsFiltration,
    ExaminationBadge,
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, changeSorting, getColumnSorting,
    } = cadreSafetyTrainings.value;

    const { modalAddTraining, modalEditTraining } = useCadreModals();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (data) => modalEditTraining.value.open(data),
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: async (training) => {
          const confirmation = await deleteModal({
            title: i18n.t('cadre.safetyTrainings.modal.delete.title'),
            text: i18n.t('cadre.safetyTrainings.modal.delete.text', {
              name: training.name,
            }),
          });

          if (!confirmation) return false;

          return useCadreApi()
            .deleteSafetyTraining(training.id)
            .then(() => {
              showToast(DeleteTrainingSuccess);
              fetchData();
            })
            .catch(() => showToast(DeleteTrainingError));
        },
      },
    ];

    const openAttachment = (attachment) => {
      if (!attachment?.file_link) return;

      window.open(attachment.file_link, '_blank');
    };

    const openTrainingAddModal = () => {
      modalAddTraining.value.open();
    };

    const onSortChange = (data) => {
      const { field, type } = data[0];
      changeSorting({ field, type });
    };
    const getColumnSortType = (field) => getColumnSorting(field)?.type;

    return {
      table,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      openAttachment,
      fetchData,
      openTrainingAddModal,
      daysFromToday,
      onSortChange,
      getColumnSortType,
    };
  },
  data() {
    return {
      searchTermTimeout: null,
      tableOptions: {
        isLoading: false,
      },
      filterParams: [],
    };
  },
  computed: {
    ...mapGetters({}),
  },
};
</script>
