var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Grupa"
    }
  }, [_c('cadre-group-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.groups_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "groups_id", $$v);
      },
      expression: "filters.groups_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Stanowisko"
    }
  }, [_c('cadre-position-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.positions_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "positions_id", $$v);
      },
      expression: "filters.positions_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Region"
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.branches_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "branches_id", $$v);
      },
      expression: "filters.branches_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kursy/uprawnienia"
    }
  }, [_c('cadre-skill-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.skills_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "skills_id", $$v);
      },
      expression: "filters.skills_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status"
    }
  }, [_c('cadre-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.status_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status_id", $$v);
      },
      expression: "filters.status_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Przełożony"
    }
  }, [_c('cadre-employee-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.supervisor_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "supervisor_id", $$v);
      },
      expression: "filters.supervisor_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "trashed-wrapper"
  }, [_c('label', [_c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.filters.with_trashed,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "with_trashed", $$v);
      },
      expression: "filters.with_trashed"
    }
  }), _vm._v(" Pokazuj zarchiwizowanych użytkowników ")], 1)])])], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }