<template>
  <b-card>

    <authorization-guard
      permission="cadre.medical_exam.index"
      silent
    >
      <cadre-employees-examinations-filtration />
    </authorization-guard>

    <div class="buttons">
      <div class="group">

        <vs-button
          v-b-modal.modal-cadre-employee-add
          vs-variant="primary"
          v-can:store="'cadre.medical_exam'"
          @click="openExaminationAddModal"
        >
          + {{ $t('cadre.medicalExams.list.button.add') }}
        </vs-button>

        <vs-button
          v-if="isModuleEnabled('Tasks')"
          vs-variant="light"
          @click="openExaminationTaskModal"
          v-can:store="'tasks.task'"
          >
          Zleć badanie
        </vs-button>

      </div>
    </div>
    <br>

    <authorization-guard
      permission="cadre.medical_exam.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.cadre.examinations.examinations"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="getColumnSortType"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        :showSearch="false"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        @on-sort-change="onSortChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'medical_exam_type'">
            {{ $t(`cadre.medicalExams.type.${row.medical_exam_type}`) }}
          </span>
          <span v-else-if="column.field === 'employee'">
            <cadre-employee-label
              isEmployee
              withAvatar
              nameOnly
              :data="row.employee"
            />
          </span>
          <span v-else-if="column.field === 'exam_date'">
            {{ formatDate.training(row.exam_date) }}
          </span>
          <span v-else-if="column.field === 'expiration_date'">
            <examination-badge
              :expired="daysFromToday(row.expiration_date) > 0"
              :about-to-expire="row && row.about_to_expire"
              :date="row.expiration_date"
              v-if="row.expiration_date"
            />
            <span v-else>-</span>
          </span>
          <span v-else-if="column.field === 'attachment'">
            <vs-button
              vs-variant="light"
              @click="() => openAttachment(row.file)"
              v-if="row.file"
              >
              Pobierz załącznik
            </vs-button>
          </span>
        </template>
      </vs-table>
    </authorization-guard>

    <cadre-examination-add
      @success="fetchData"
    />
    <cadre-examination-edit
      @success="fetchData"
    />
    <tasks-create-modal />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BCard,
} from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import cadreExaminationColumns from '@/hooks/tables/cadre/examinations';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import CadreEmployeeLabel from '@/components/views/cadre/ui/CadreEmployeeLabel.vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import CadreExaminationAdd from '@/components/views/cadre/ui/examinations/modals/CadreExaminationAdd.vue';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import CadreExaminationEdit from '@/components/views/cadre/ui/examinations/modals/CadreExaminationEdit.vue';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import CadreEmployeesExaminationsFiltration from '@/components/views/cadre/CadreEmployeesExaminationsFiltration.vue';
import TasksCreateModal from '@/components/views/tasks/modals/TasksCreateModal.vue';
import useTasksModals from '@/hooks/tasks/useTasksModals';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import showToast from '@/libs/toasts';
import { DeleteExaminationError, DeleteExaminationSuccess } from '@/libs/toasts/feedback/module/cadre';

export default {
  components: {
    BCard,

    VsButton,
    VsTable,

    AuthorizationGuard,
    CadreEmployeeLabel,
    CadreExaminationAdd,
    CadreExaminationEdit,
    ExaminationBadge,
    CadreEmployeesExaminationsFiltration,
    TasksCreateModal,
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, changeSorting, getColumnSorting,
    } = cadreExaminationColumns.value;
    const { modalAddExamination, modalEditExamination } = useCadreModals();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (examination) => {
          modalEditExamination.value.open(examination);
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: async (examination) => {
          const confirmation = await deleteModal({
            title: i18n.t('cadre.medicalExams.modal.delete.title'),
            text: i18n.t('cadre.medicalExams.modal.delete.text', {
              date: formatDate.training(examination.exam_date),
            }),
          });

          if (!confirmation) return false;

          return useCadreApi()
            .deleteMedicalExamination(examination.id)
            .then(() => {
              showToast(DeleteExaminationSuccess);
              fetchData();
            })
            .catch(() => showToast(DeleteExaminationError));
        },
      },
    ];

    const openAttachment = (attachment) => {
      if (!attachment?.file_link) return;

      window.open(attachment.file_link, '_blank');
    };

    const openExaminationAddModal = () => {
      modalAddExamination.value.open();
    };

    const { modalCreateTask } = useTasksModals();
    const openExaminationTaskModal = () => {
      modalCreateTask.value.open({
        title: 'Wykonaj badanie lekarskie',
        priority_id: 3,
      });
    };

    const onSortChange = (data) => {
      const { field, type } = data[0];
      changeSorting({ field, type });
    };
    const getColumnSortType = (field) => getColumnSorting(field)?.type;

    return {
      table,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      openAttachment,
      openExaminationAddModal,
      fetchData,
      openExaminationTaskModal,
      daysFromToday,
      onSortChange,
      getColumnSortType,
    };
  },
  data() {
    return {
      searchTermTimeout: null,
      tableOptions: {
        isLoading: false,
      },
      filterParams: [],
    };
  },
  created() {
  },
  computed: {
    ...mapGetters({
      isModuleEnabled: 'app/isModuleEnabled',
    }),
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: 'cadre.employee.details', params: { id: data.row.id } });
    },
  },
  watch: {
  },
};
</script>

<style lang="sass" scoped>
.buttons
  .group
    display: flex
    gap: 10px
</style>
