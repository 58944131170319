var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    on: {
      "activate-tab": _vm.changeTab
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('employees')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        }), _c('span', [_vm._v("Pracownicy")])];
      },
      proxy: true
    }])
  }, [_c('cadre-employees-list')], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('examinations')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ClipboardIcon"
          }
        }), _c('span', [_vm._v("Badania lekarskie")])];
      },
      proxy: true
    }])
  }, [_c('cadre-employees-examinations-list')], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('safety-trainings')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "LifeBuoyIcon"
          }
        }), _c('span', [_vm._v("Szkolenia BHP")])];
      },
      proxy: true
    }])
  }, [_c('cadre-employees-safety-trainings-list')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }