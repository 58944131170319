var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data ważności od"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.filters.date_from,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_from", $$v);
      },
      expression: "filters.date_from"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data ważności do"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.filters.date_to,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "date_to", $$v);
      },
      expression: "filters.date_to"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pracownik"
    }
  }, [_c('cadre-employee-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.employee_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "employee_ids", $$v);
      },
      expression: "filters.employee_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }