var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "cadre.employee.index",
      "silent": ""
    }
  }, [_c('filtration')], 1), _c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group d-flex justify-content-between"
  }, [_c('vs-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-cadre-employee-add",
      modifiers: {
        "modal-cadre-employee-add": true
      }
    }, {
      name: "can",
      rawName: "v-can:store",
      value: 'cadre.employee',
      expression: "'cadre.employee'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('global.button.employee')) + " ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.handleExport
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "14",
      "icon": "DownloadIcon"
    }
  })], 1), _c('a', {
    ref: "exportAnchorRef",
    staticClass: "d-none"
  })], 1)]), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "cadre.employee.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.cadre.employees.employees,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": _vm.getColumnSortType,
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'surname' ? _c('span', [_c('cadre-employee-label', {
          attrs: {
            "withAvatar": "",
            "noProfession": "",
            "data": row
          },
          scopedSlots: _vm._u([row.deleted_at ? {
            key: "subtitle",
            fn: function () {
              return [_vm._v(" Zarchiwizowany ")];
            },
            proxy: true
          } : null], null, true)
        })], 1) : _vm._e(), column.field === 'branch_id' ? _c('span', [_c('branch-label', {
          attrs: {
            "id": row.company_branch_id
          }
        })], 1) : _vm._e(), column.field === 'group_id' ? _c('span', [row.group ? _c('group-label', {
          attrs: {
            "id": row.group.id
          }
        }) : _vm._e()], 1) : _vm._e(), column.field === 'position_id' ? _c('span', [row.position ? _c('position-label', {
          attrs: {
            "id": row.position.id
          }
        }) : _vm._e()], 1) : _vm._e(), column.field === 'contract_end' ? _c('span', [row.contract_end ? _c('examination-badge', {
          attrs: {
            "expired": _vm.daysFromToday(row.contract_end) > 0,
            "about-to-expire": _vm.daysFromToday(row.contract_end) >= -30,
            "date": row.contract_end
          }
        }) : _c('span', {
          staticStyle: {
            "opacity": "0.6"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('cadre.list.field.indefinite')) + " ")])], 1) : column.field === 'medical_exams' ? _c('span', [row.medical_exam_required ? _c('examination-badge', {
          attrs: {
            "expired": !row.medical_exam || !row.medical_exam.up_to_date,
            "about-to-expire": row.medical_exam && row.medical_exam.about_to_expire,
            "date": row.medical_exam ? row.medical_exam.expiration_date : null
          }
        }) : _c('span', {
          staticStyle: {
            "opacity": "0.6"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('cadre.medicalExams.field.notApplicable')) + " ")])], 1) : column.field === 'status_id' ? _c('span', [_c('status-badge', {
          attrs: {
            "status_id": row.status_id
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }