import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const cadreExaminationColumns = [
  {
    label: 'Typ badania',
    field: 'medical_exam_type',
    showInDetails: true,
  },
  {
    label: 'Pracownik',
    field: 'employee',
  },
  {
    label: 'Data badania',
    field: 'exam_date',
  },
  {
    label: 'Data wygaśnięcia',
    field: 'expiration_date',
  },
  {
    label: 'Załącznik',
    field: 'attachment',
  },
];

export default ref(createTable('cadre/examinations', cadreExaminationColumns, {
  filters: {
    date_from: null,
    date_to: null,
    employee_ids: [],
  },
  sortable: ['expiration_date', 'exam_date'],
}));
