var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre"
  }, [_c('div', {
    staticClass: "listWrapper",
    class: {
      fullWidth: !_vm.detailsVisible()
    }
  }, [_c('cadre-list')], 1), _c('cadre-employee-add')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }