<template>
  <b-card>
    <authorization-guard permission="cadre.employee.index" silent>
      <filtration />
    </authorization-guard>

    <div class="buttons">
      <div class="group d-flex justify-content-between">
        <vs-button
          v-b-modal.modal-cadre-employee-add
          vs-variant="primary"
          v-can:store="'cadre.employee'"
        >
          + {{ $t('global.button.employee') }}
        </vs-button>

        <vs-button vs-variant="light" @click="handleExport">
          <feather-icon size="14" icon="DownloadIcon" />
        </vs-button>

        <a class="d-none" ref="exportAnchorRef" />
      </div>
    </div>
    <br />

    <authorization-guard permission="cadre.employee.index">
      <vs-table
        showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.cadre.employees.employees"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="getColumnSortType"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        @on-sort-change="onSortChange"
      >
        <template v-slot:row="{ column, row }">
          <span v-if="column.field === 'surname'">
            <cadre-employee-label withAvatar noProfession :data="row">
              <template #subtitle v-if="row.deleted_at">
                Zarchiwizowany
              </template>
            </cadre-employee-label>
          </span>
          <span v-if="column.field === 'branch_id'">
            <branch-label :id="row.company_branch_id" />
          </span>
          <span v-if="column.field === 'group_id'">
            <group-label v-if="row.group" :id="row.group.id" />
          </span>
          <span v-if="column.field === 'position_id'">
            <position-label v-if="row.position" :id="row.position.id" />
          </span>
          <span v-if="column.field === 'contract_end'">
            <examination-badge
              v-if="row.contract_end"
              :expired="daysFromToday(row.contract_end) > 0"
              :about-to-expire="daysFromToday(row.contract_end) >= -30"
              :date="row.contract_end"
            />
            <span style="opacity: 0.6" v-else>
              {{ $t('cadre.list.field.indefinite') }}
            </span>
          </span>
          <span v-else-if="column.field === 'medical_exams'">
            <examination-badge
              v-if="row.medical_exam_required"
              :expired="!row.medical_exam || !row.medical_exam.up_to_date"
              :about-to-expire="
                row.medical_exam && row.medical_exam.about_to_expire
              "
              :date="row.medical_exam ? row.medical_exam.expiration_date : null"
            />
            <span style="opacity: 0.6" v-else>
              {{ $t('cadre.medicalExams.field.notApplicable') }}
            </span>
          </span>
          <span v-else-if="column.field === 'status_id'">
            <status-badge :status_id="row.status_id" />
          </span>
        </template>
      </vs-table>
    </authorization-guard>
  </b-card>
</template>

<script>
import router from '@/router';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import { mapGetters } from 'vuex';
import { BCard } from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import StatusBadge from '@/components/ui/status-badge/Cadre.vue';
import { cadre } from '@/hooks/tables';
import BranchLabel from '@/components/ui/BranchLabel.vue';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';

import GroupLabel from '@/components/views/cadre/ui/GroupLabel.vue';
import PositionLabel from '@/components/views/cadre/ui/PositionLabel.vue';

import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import showToast from '@/libs/toasts';
import {
  ExportDataSuccess,
  ExportDataError,
} from '@/libs/toasts/feedback/module/cadre';
import CadreEmployeeLabel from '../ui/CadreEmployeeLabel.vue';
import Filtration from './Filtration.vue';

export default {
  components: {
    BCard,

    Filtration,
    VsButton,
    VsTable,
    StatusBadge,
    BranchLabel,
    ExaminationBadge,

    GroupLabel,
    PositionLabel,
    AuthorizationGuard,
    CadreEmployeeLabel,
  },
  setup() {
    const {
      table,
      updateSearchTerm,
      searchTerm,
      filters,
      columns,
      fetchData,
      onPageChange,
      changeSorting,
      getColumnSorting,
      getFetchParams,
    } = cadre.value;

    fetchData();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          router.push({ name: 'cadre.employee.edit', params: { id: obj.id } });
        },
      },
    ];

    const onSortChange = (data) => {
      const { field, type } = data[0];
      changeSorting({ field, type });
    };

    const getColumnSortType = (field) => getColumnSorting(field)?.type;

    return {
      table,
      updateSearchTerm,
      searchTerm,
      columns,
      filters,
      onPageChange,
      actionItems,
      onSortChange,
      getColumnSortType,
      getFetchParams,
    };
  },
  data() {
    return {
      searchTermTimeout: null,
      tableOptions: {
        isLoading: false,
      },
      filterParams: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    openDetails(data) {
      this.$router.push({
        name: 'cadre.employee.details',
        params: { id: data.row.id },
      });
    },
    daysFromToday,
    async handleExport() {
      const anchorRef = this.$refs.exportAnchorRef;
      if (!anchorRef) return;

      const fetchParams = this.getFetchParams();

      useCadreApi()
        .exportData(fetchParams)
        .then(({ data }) => {
          const timestamp = formatDate.standardTimed(Date.now());
          anchorRef.href = `data:application/xlsx;base64,${data.data}`;
          anchorRef.download = `Budokop - Kadra - ${timestamp}.xlsx`;
          anchorRef.click();
          showToast(ExportDataSuccess);
        })
        .catch((err) => {
          showToast(ExportDataError);
          console.log('error ', err);
        });
    },
  },
  watch: {},
};
</script>
