<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Grupa"
            >
            <cadre-group-picker multiple v-model="filters.groups_id"/>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Stanowisko"
            >
            <cadre-position-picker multiple v-model="filters.positions_id" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Region"
            >
            <core-branch-picker multiple v-model="filters.branches_id" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Kursy/uprawnienia"
            >
            <cadre-skill-picker multiple v-model="filters.skills_id" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Status"
            >
            <cadre-status-picker multiple v-model="filters.status_id" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Przełożony"
            >
            <cadre-employee-picker
              withDeleted
              multiple
              v-model="filters.supervisor_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <div class="trashed-wrapper">
              <label>
                <b-form-checkbox switch
                  v-model="filters.with_trashed"
                />
                Pokazuj zarchiwizowanych użytkowników
              </label>
            </div>
          </b-form-group>
        </b-col>

      </b-row>
      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue';
import { cadre } from '@/hooks/tables';
import CadreGroupPicker from '@/components/module/cadre/CadreGroupPicker.vue';
import CadrePositionPicker from '@/components/module/cadre/CadrePositionPicker.vue';
import CadreSkillPicker from '@/components/module/cadre/CadreSkillPicker.vue';
import CadreStatusPicker from '@/components/module/cadre/CadreStatusPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';

export default {
  setup() {
    const { table, clearAllFilters, filters } = cadre.value;

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormCheckbox,

    CadrePositionPicker,
    CadreSkillPicker,
    CadreStatusPicker,
    CadreGroupPicker,
    CoreBranchPicker,
    CadreEmployeePicker,
  },
};
</script>

<style lang="sass" scoped>
.trashed-wrapper
  label
    display: flex
    align-items: center
    margin-top: 3px
</style>
