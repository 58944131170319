<template>
  <div class="cadre">

    <div class="listWrapper" :class="{ fullWidth: !detailsVisible() }">
      <cadre-list />
    </div>

    <cadre-employee-add />

  </div>
</template>

<script>
import CadreList from '@/components/views/cadre/List.vue';
import CadreEmployeeAdd from '@/components/views/cadre/modals/Add.vue';

export default {
  components: {
    CadreList,
    CadreEmployeeAdd,
  },
  methods: {
    detailsVisible() {
      return this.$route.name === 'cadre-details';
    },
  },
};
</script>
