<template>
  <vs-modal
    :modalInstance="modalEditTraining"
    @ok="updateTraining"
  >
    <cadre-training-form
      hideEmployeePicker
      v-model="fields"
      :feedback="feedback"
      :editedEmployee="editedEmployee"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import {
  UpdateTrainingSuccess,
  UpdateTrainingError,
} from '@/libs/toasts/feedback/module/cadre';
import showToast from '@/libs/toasts';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import { ref, watch } from 'vue';
import CadreTrainingForm from '../CadreTrainingForm.vue';

export default {
  name: 'CadreTrainingEdit',
  emits: ['success', 'fail'],
  setup(_, { emit }) {
    const { modalEditTraining } = useCadreModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      resetFields,
      errors,
      assignFields,
    } = useValidatedForm({
      id: 'integer|required',
      name: 'required',
      employee_id: 'integer|required',
      training_date: 'required',
      expiration_date: '',
      file: '',
    });

    const editedEmployee = ref(null);

    watch(() => modalEditTraining.value.visible, () => {
      if (!modalEditTraining.value.visible) return;
      if (modalEditTraining.value.data) {
        assignFields(modalEditTraining.value.data);
        editedEmployee.value = modalEditTraining.value.data?.employee;
      }
    });

    const updateTraining = async () => {
      if (!await areAllFieldsValid()) return;

      modalEditTraining.value.isLoading = true;
      const formData = new FormData();

      // TODO: move to a helper
      Object.keys(fields?.value).forEach((e) => {
        const name = fields?.value?.[e];
        if (name === null) return;
        formData.append(e, name);
      });

      const request = useCadreApi().updateSafetyTraining(fields.value.id, formData);

      request
        .then(({ data }) => {
          resetFields();

          showToast(UpdateTrainingSuccess);
          emit('success', data);
          modalEditTraining.value.close();
        })
        .catch(({ response }) => {
          showToast(UpdateTrainingError);
          errors.serverSide.value = response?.data?.errors;
          emit('fail', response);
        })
        .finally(() => {
          modalEditTraining.value.isLoading = false;
        });
    };

    return {
      modalEditTraining,
      updateTraining,
      fields,
      feedback,
      editedEmployee,
    };
  },
  components: {
    VsModal,
    CadreTrainingForm,
  },
};
</script>
