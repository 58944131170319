var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-cadre-employee-add",
      "title": _vm.$t('cadre.employee.add.title'),
      "cancel-variant": "outline-secondary",
      "cancel-title": _vm.$t('cadre.employee.add.button.cancel'),
      "ok-title": _vm.$t('cadre.employee.add.button.save'),
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.createEmployee
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('vs-modal-footer', {
          attrs: {
            "is-loading": _vm.loading,
            "text-cancel": _vm.$t('cadre.employee.add.button.cancel'),
            "text-ok": _vm.$t('cadre.employee.add.button.save')
          },
          on: {
            "ok": ok,
            "cancel": cancel
          }
        })];
      }
    }])
  }, [_vm.loading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Dodawanie pracownika..."
    }
  }) : _vm._e(), _c('b-card-text', [_c('employee-form', {
    attrs: {
      "feedback": _vm.errors
    },
    model: {
      value: _vm.employee,
      callback: function ($$v) {
        _vm.employee = $$v;
      },
      expression: "employee"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }