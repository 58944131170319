import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const cadreSafetyTrainingsColumns = [
  {
    label: 'Nazwa szkolenia',
    field: 'name',
    showInDetails: true,
  },
  {
    label: 'Pracownik',
    field: 'employee',
  },
  {
    label: 'Data szkolenia',
    field: 'training_date',
  },
  {
    label: 'Data wygaśnięcia',
    field: 'expiration_date',
  },
  {
    label: 'Załącznik',
    field: 'attachment',
  },
];

export default ref(createTable('cadre/trainings', cadreSafetyTrainingsColumns, {
  filters: {
    date_from: null,
    date_to: null,
    employee_ids: [],
  },
  sortable: ['training_date', 'expiration_date'],
}));
