<template>
  <b-tabs
    @activate-tab="changeTab"
  >

    <b-tab
      lazy
      :active="isTabActive('employees')"
      >
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>Pracownicy</span>
      </template>

      <cadre-employees-list />
    </b-tab>

    <b-tab lazy :active="isTabActive('examinations')">
      <template #title>
        <feather-icon icon="ClipboardIcon" />
        <span>Badania lekarskie</span>
      </template>

      <cadre-employees-examinations-list />
    </b-tab>

    <b-tab lazy :active="isTabActive('safety-trainings')">
      <template #title>
        <feather-icon icon="LifeBuoyIcon" />
        <span>Szkolenia BHP</span>
      </template>

      <cadre-employees-safety-trainings-list />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue';
import { computed } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import CadreEmployeesList from '@/views/cadre/List.vue';
import CadreEmployeesExaminationsList from '../CadreEmployeesExaminationsList.vue';
import CadreEmployeesSafetyTrainingsList from '../CadreEmployeesSafetyTrainingsList.vue';

export default {
  name: 'CadreEmployees',
  setup() {
    /**
     * route-based tabs should be looked into
     *
     * FIXME: refactor this and probably get rid of
     * activetab meta inside specific routes
     */
    const { route, router } = useRouter();

    const activeTab = computed(() => route.value.meta?.activeTab);
    const isTabActive = (tabName) => activeTab.value === tabName;

    const tabRoutes = {
      0: 'cadre',
      1: 'cadre.examinations',
      2: 'cadre.safety-trainings',
    };

    const changeTab = (to) => {
      const desiredRoute = tabRoutes?.[to];

      if (!desiredRoute) return;
      if (desiredRoute === route.value.name) return;

      router.push({
        name: desiredRoute,
      });
    };

    return { isTabActive, activeTab, changeTab };
  },
  components: {
    CadreEmployeesList,
    BTabs,
    BTab,
    CadreEmployeesExaminationsList,
    CadreEmployeesSafetyTrainingsList,
  },
};
</script>

<style scoped>

</style>
