<template>
  <vs-modal
    :modalInstance="modalEditExamination"
    @ok="editExamination"
  >
    <cadre-examination-form
      v-model="fields"
      :feedback="feedback"
      :editedEmployee="editedEmployee"
    />
  </vs-modal>
</template>

<script>
import CadreExaminationForm from '@/components/views/cadre/ui/examinations/CadreExaminationForm.vue';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import { ref, watch } from 'vue';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import showToast from '@/libs/toasts';
import { UpdateExaminationError, UpdateExaminationSuccess } from '@/libs/toasts/feedback/module/cadre';

export default {
  name: 'CadreExaminationEdit',
  setup(_, { emit }) {
    const { modalEditExamination } = useCadreModals();
    const editedEmployee = ref({});

    const {
      fields,
      feedback,
      areAllFieldsValid,
      resetFields,
      errors,
      assignFields,
      setFieldDefaults,
    } = useValidatedForm({
      medical_exam_type: 'required',
      id: 'integer|required',
      employee_id: 'integer|required',
      exam_date: 'required',
      expiration_date: '',
      file: '',
    });

    setFieldDefaults({ medical_exam_type: 'EARLY' });

    watch(() => modalEditExamination.value.visible, () => {
      if (!modalEditExamination.value.visible) return;
      if (modalEditExamination.value.data) {
        assignFields(modalEditExamination.value.data);
        editedEmployee.value = modalEditExamination.value.data?.employee;
      }
    });

    const editExamination = async () => {
      if (!await areAllFieldsValid()) return;
      modalEditExamination.value.isLoading = true;

      const formData = new FormData();

      Object.keys(fields?.value).forEach((e) => {
        const name = fields?.value?.[e];
        if (name === null) return;
        formData.append(e, name);
      });

      const request = useCadreApi()
        .updateMedicalExamination(fields.value.id, formData);

      request
        .then(({ data }) => {
          resetFields();
          showToast(UpdateExaminationSuccess);
          emit('success', data);
          modalEditExamination.value.close();
        })
        .catch(({ response }) => {
          showToast(UpdateExaminationError);
          errors.serverSide.value = response?.data?.errors;
          emit('fail', response);
        })
        .finally(() => { modalEditExamination.value.isLoading = false; });
    };

    return {
      fields,
      modalEditExamination,
      feedback,
      editExamination,
      editedEmployee,
    };
  },
  components: {
    CadreExaminationForm,
    VsModal,
  },
};
</script>
