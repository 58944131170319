<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Data ważności od"
            >
            <vs-datepicker v-model="filters.date_from" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Data ważności do"
            >
            <vs-datepicker v-model="filters.date_to" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Pracownik"
            >
            <cadre-employee-picker
              withDeleted
              multiple
              v-model="filters.employee_ids"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BFormGroup } from 'bootstrap-vue';
import cadreExaminations from '@/hooks/tables/cadre/examinations';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import { useRouter } from '@/@core/utils/utils';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';

export default {
  name: 'CadreEmployeesExaminationsFiltration',
  setup() {
    const {
      table, filters, clearAllFilters, fetchData,
    } = cadreExaminations.value;

    cadreExaminations.value.shouldUpdateTableOnFilterChange = false;

    const { route } = useRouter();
    const employeeParam = route.value.params?.employeeId;
    if (employeeParam) {
      filters.employee_ids = [employeeParam];
    }

    setTimeout(() => {
      cadreExaminations.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    CadreEmployeePicker,
    VsDatepicker,
  },
};
</script>
