var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "cadre.medical_exam.index",
      "silent": ""
    }
  }, [_c('cadre-employees-examinations-filtration')], 1), _c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-cadre-employee-add",
      modifiers: {
        "modal-cadre-employee-add": true
      }
    }, {
      name: "can",
      rawName: "v-can:store",
      value: 'cadre.medical_exam',
      expression: "'cadre.medical_exam'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openExaminationAddModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('cadre.medicalExams.list.button.add')) + " ")]), _vm.isModuleEnabled('Tasks') ? _c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'tasks.task',
      expression: "'tasks.task'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.openExaminationTaskModal
    }
  }, [_vm._v(" Zleć badanie ")]) : _vm._e()], 1)]), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "cadre.medical_exam.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.cadre.examinations.examinations,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": _vm.getColumnSortType,
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading,
      "showSearch": false
    },
    on: {
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'medical_exam_type' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(`cadre.medicalExams.type.${row.medical_exam_type}`)) + " ")]) : column.field === 'employee' ? _c('span', [_c('cadre-employee-label', {
          attrs: {
            "isEmployee": "",
            "withAvatar": "",
            "nameOnly": "",
            "data": row.employee
          }
        })], 1) : column.field === 'exam_date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.training(row.exam_date)) + " ")]) : column.field === 'expiration_date' ? _c('span', [row.expiration_date ? _c('examination-badge', {
          attrs: {
            "expired": _vm.daysFromToday(row.expiration_date) > 0,
            "about-to-expire": row && row.about_to_expire,
            "date": row.expiration_date
          }
        }) : _c('span', [_vm._v("-")])], 1) : column.field === 'attachment' ? _c('span', [row.file ? _c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": function () {
              return _vm.openAttachment(row.file);
            }
          }
        }, [_vm._v(" Pobierz załącznik ")]) : _vm._e()], 1) : _vm._e()];
      }
    }])
  })], 1), _c('cadre-examination-add', {
    on: {
      "success": _vm.fetchData
    }
  }), _c('cadre-examination-edit', {
    on: {
      "success": _vm.fetchData
    }
  }), _c('tasks-create-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }