<template>
  <div class="add-modal">
    <b-modal
      id="modal-cadre-employee-add"
      :title="$t('cadre.employee.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('cadre.employee.add.button.cancel')"
      :ok-title="$t('cadre.employee.add.button.save')"
      size="lg"
      @ok="createEmployee"
      no-close-on-backdrop
      >
      <vs-loader
        full
        text="Dodawanie pracownika..."
        v-if="loading"
      />

      <b-card-text>
        <employee-form v-model="employee" :feedback="errors"/>
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          :is-loading="loading"

          :text-cancel="$t('cadre.employee.add.button.cancel')"
          :text-ok="$t('cadre.employee.add.button.save')"

          @ok="ok"
          @cancel="cancel"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import EmployeeForm from '@/components/views/cadre/Form.vue';
import { AddSuccess, ServerError, BadInputError } from '@feedback/module/cadre';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  components: {
    BModal,
    BCardText,

    EmployeeForm,
    VsModalFooter,
    VsLoader,
  },
  data() {
    return {
      loading: false,
      employee: {},
      errors: {},
    };
  },
  methods: {
    goToDetails(employee) {
      this.$router.push({
        name: 'cadre.employee.details',
        params: {
          id: employee.id,
        },
      });
    },
    createEmployee(e) {
      e.preventDefault();
      this.loading = true;

      this.$store
        .dispatch('cadre/employees/create', this.employee)
        .then(({ data }) => {
          this.$bvModal.hide('modal-cadre-employee-add');

          this.$showFeedback(AddSuccess, {
            name: data.data.full_name,
          }, () => this.goToDetails(data.data));

          RemoteResourceManager.getInstance().notify('cadre.employee');
          if (data.data.user_core_id) {
            RemoteResourceManager.getInstance()
              .notify('core.user');
          }

          this.loading = false;
        })
        .catch((err) => {
          let Feedback = ServerError;

          if (err.response.data.errors) {
            Feedback = BadInputError;
          }

          this.$showFeedback(Feedback, { id: 'siema' });
          this.loading = false;
          this.errors = err.response.data.errors;
        });
    },
  },
};
</script>
